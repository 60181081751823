import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import Box from './components/Box/Box'
import Input from './components/Input/Input'

import * as styles from './wordle.module.scss'

const Wordle = () => {
  const [wordArray, setWordArray] = useState('HELLO'.split(''))
  const [colorGridArray, setColorGridArray] = useState([[], [], [], [], []])
  const [activeRow, setActiveRow] = useState(0)
  const [numRows, setNumRows] = useState(0)
  const [numTries, setNumTries] = useState(0)
  const [success, setSuccess] = useState(false)

  const handleSubmit = (e, value) => {
    e.preventDefault()
    handleGuess(value)
  }

  const handleGuess = (guess) => {
    const formattedGuess = guess.toUpperCase().split('');
    let pushColor = []
    for (var i = 0; i <= wordArray.length; i++) {
      // if the letter exists in array
        // if the letter is in the right spot - GREEN
        // if the letter is in the wrong spot - YELLOW

      // if letter does not exist in the array
        // gray

      if (wordArray.includes(formattedGuess[i])) {
        if (wordArray[i] === formattedGuess[i]) {
          pushColor.push('green')
        } else {
          pushColor.push('yellow')
        }
      } else {
        pushColor.push('gray')
      }

      colorGridArray[activeRow] = pushColor
      setColorGridArray(colorGridArray)
      setNumTries(numTries + 1)
      setActiveRow(activeRow + 1)
    }
  }

  useEffect(() => {
    if (checkSuccess(colorGridArray)) {
      setSuccess(true)
    } else {
      const addRow = numRows + 1
      setNumRows(addRow)
    }
  }, [colorGridArray])

  const checkSuccess = (array) => {
    let count = 0
    for (var i = 0; i < array.length; i++) {
      if (array[i] === 'green') {
        count += 1
      }
    }
    if (count === 5) {
      return true
    } else {
      return false
    }
  }

  const Row = ({
    wordArray,
    colorGridArray
  }) => {
    return (
    <div className={styles.row}>
      <p>{`${success}`}</p>
      {
        wordArray.map((letter, idx) => (
          <Box
            className={styles.rowBox}
            color={colorGridArray[idx]}
          />
        ))
      }
    </div>
  )}

  return (
    <div className={styles.Wordle}>
      <p>Wordle</p>
      <Input 
        onSubmit={handleSubmit}
      />
      <div>
        {
          colorGridArray.map((row, idx) => {
            return row.length > 0 ? (
              <Row 
                wordArray={wordArray}
                colorGridArray={row}
              />
            ) : null
          })
        }
      </div>
    </div>
  )
}

export default Wordle